mcWeb.ajaxVariantSelector = new (function ($) {
	'use strict';

    var obj = this;
    obj.view;

    obj.getModule = function(me){
        var myContainer = $(me).closest('.ajax-vsc');
        var myId = $(myContainer).data("v-unique-id");
        var myModule = mcWeb.ajaxVariantSelector.instances[myId];

        return myModule;
    };
   
    

    this.vm = function(parent){
        obj.view = parent;

        var self = this;
        self.id = parent.id+"-avs";
        self.parentId = parent.id;
        self.initialised = false;
        self.storeSelectionInBrowser = false;
	    self.service = new WebApiService("ProductVariant");
        self.subscriptionsAdded = false;
        self.isVariantMother = false;
        self.load = function(data,settings){};

        self.registerModule = function(){
            var myContainer = $(self.parentId);
             $(myContainer).addClass("ajax-vsc");
             $(myContainer).attr('data-v-unique-id',self.id);

            if (mcWeb.ajaxVariantSelector.instances == undefined)
                mcWeb.ajaxVariantSelector.instances = [];

             mcWeb.ajaxVariantSelector.instances[self.id] = self;
        };

        self.bind = function(data){
            if (!self.initialised) {
                self.variantType1Id = null;
                self.variantType2Id = null;

                if(data !== undefined){
                    if(data.storeInBrowser){
                        self.storeSelectionInBrowser = true;
                    }

                    if (data.loadSelectedValuesFromDOM) {
                        var domAttr1 = jQuery('.variant-selector-container .v-container.active[data-variant-type="1"]');
                        if (domAttr1.length > 0) {
                            self.variantType1Id = jQuery(domAttr1[0]).data('attributeValueId');
                        }

                        var domAttr2 = jQuery('.variant-selector-container .v-container.active[data-variant-type="2"]');
                        if (domAttr2.length > 0) {
                            self.variantType2Id = jQuery(domAttr2[0]).data('attributeValueId');
                        }
                    }

                    if(obj.view.isVariantMother()){
                        self.isVariantMother = true;
                    }
                    if(obj.view.motherPlid != undefined){
                        self.motherPlid = obj.view.motherPlid();
                    }
                    
                }

                self.registerModule();
                self.initialised = true;
            }
        };
        self.reinit = function(){
            if(self.storeSelectionInBrowser){
                self.restoreDataFromLocalStorage(self.motherPlid);
            }
            //todo: refresh classes in html based on data found on self
            if(self.variantType1Id !== undefined && self.variantType1Id !== null && self.variantType1Id >=0){
                if(self.variantType2Id !== undefined && self.isVariantMother ){
                    //lets highlight the correct variant of type 1 and so on, which will try to do by using the selectVariant method for the selected attribute
                    var variantTypeToActivate = $(self.parentId).find(".v-container[data-attribute-value-id="+ self.variantType1Id+"]")[0];
                    if(variantTypeToActivate != undefined && variantTypeToActivate != null)
                        self.selectVariant(variantTypeToActivate)
                }
            }
        };

        self.selectVariant = function(me){

            var $me = $( $(me).find("a[data-attribute-value-id]")[0]);

            if(!self.subscriptionsAdded){
                //we need to reset this info when the lightbox is closed
                PubSub.subscribe(mcWeb.lightbox.events.onHideLightbox, function () {
                    self.variantType1Id = null;
                    self.variantType2Id = null;

                });
                self.subscriptionsAdded = true;
            }

            var variantType =$me.data('variant-type');
            var attributeValueId = $me.data('attribute-value-id');
            var valueActive = $me.data('active');
            var otherAttributeValueId = null;
            var motherPlid =$me.data('mother-plid');
            var myContainer = $me.closest('.variant-selector-container');
            var variantTypeCount = myContainer.data('variant-types');
            var removeOldSelected = false;
            if(variantType == 1){
                if(self.variantType1Id  != null && (self.variantType2Id == null || self.variantType2Id == -1 )){
                    //must reset selected class for attrib 1
                    removeOldSelected = true;
                }
                self.variantType1Id = attributeValueId;
                otherAttributeValueId = self.variantType2Id;
            }
            else{
                  if((self.variantType2Id  != null && self.variantType1Id == null ) || self.variantType2Id  == -1){
                    //must reset selected class for attrib 2
                      removeOldSelected = true;
                }
                self.variantType2Id = attributeValueId;
                otherAttributeValueId = self.variantType1Id;
            }
       
            if(self.storeSelectionInBrowser){
                self.updateLocalStorage(motherPlid);
            }

           // if(valueActive){
                if(((otherAttributeValueId != null && otherAttributeValueId != -1 )|| variantTypeCount == 1 ) && attributeValueId != null ){
                    self.getChildPlidAction(motherPlid, self.variantType1Id, self.variantType2Id, variantType)
                        .done(function(result){
                            if(result && result.Success){
                                obj.view.show(result.Plid,0);
                            }
                            else{
                                console.log(result.Message);
                            }
                        });                
                }else{
                    //the other value has not been set yet
                    //remove highlighting of already hightlighted item
                    self.setupOtherVariantType(removeOldSelected, $me, motherPlid, attributeValueId, variantType);
                    //update showing of selected variant, if needed

                    var myHeadingPch = myContainer.find(".vsc-heading-value");
                    if(myHeadingPch.length > 0)
                    {
                        var attribName = $me.data("value-name");
                        myHeadingPch[0].innerText = attribName;
                    }
                }
            //}
            //else{
            //}
        
        };
       

        self.updateLocalStorage = function(plid){
            window.StorageService.localStorage.setItem('mcWeb.ajaxVariantSelector.variantType1_'+plid,self.variantType1Id);
            window.StorageService.localStorage.setItem('mcWeb.ajaxVariantSelector.variantType2_'+plid,self.variantType2Id);
        };

        self.restoreDataFromLocalStorage = function (plid) {
            if (self.isVariantMother) {
                self.variantType2Id = -1;
                self.variantType1Id = -1;
            }
            else {
                var tmp = window.StorageService.localStorage.getItem('mcWeb.ajaxVariantSelector.variantType1_' + plid);
                if (tmp != undefined)
                    self.variantType1Id = tmp;


                tmp = window.StorageService.localStorage.getItem('mcWeb.ajaxVariantSelector.variantType2_' + plid);
                if (tmp != undefined)
                    self.variantType2Id = tmp;
            }
        };

    self.setupOtherVariantType = function(removeOldSelected, $me, motherPlid, attributeValueId,variantType){
                //the other value has not been set yet
                //remove highlighting of already hightlighted item
                if(removeOldSelected)
                {
                    $(".variant-selector-container .v-container").removeClass("active");
                }
                //secondly highlight the selected item
                //todo: remove all highlighting from liste of the other variants

                var myContainer = $me.closest('.v-container');
                $(myContainer).addClass('active');
                //if the container is part of a dropdown, we need to change the text from attribute to attributevalue
                if($(myContainer).parent().hasClass("dropdown-menu")){
                    var myButton =$(myContainer).parent().parent().find("button.dropdown-toggle");
                    var myImgPlch = $(myButton).find(".color-preview-box-pch");
                    var myColour = $(myContainer).find(".color-preview-box").clone();
                    if(myColour.length > 0 && myImgPlch.length > 0){
                        $(myImgPlch[0]).html(myColour);
                    }
                    var textContainer =$(myButton).find("span.ddl-button-span");
                    var itemContainer = $(myContainer).find("a[data-mother-plid]");
                    
                    var itemText = $(itemContainer).prop("title");
                    textContainer[0].innerText =itemText;
                }


                self.getInactiveAttributeValuesAction(motherPlid,attributeValueId,variantType).done(function(result){
                    if(result && result.Success){
                        if(result.AttributeValueIds != null){
                            var otherVariantType = 2;
                            if(variantType === 2)
                                otherVariantType = 1;
                            //active all varianttypes
                            $(".v-container[data-variant-type=" + otherVariantType +"]").removeClass("disabled");//data-variant-type mangler i container div
                            $(".v-container[data-variant-type=" + otherVariantType +"]").addClass("activated");

                            //deactivate those that should not be acctive
                            for(var i = 0; i < result.AttributeValueIds.length; i++){
                                //må få inn data-attribute-value-id inn på div rundt a-taggen, og så kan vi finne de for å legge til inaktiv status
                                var itemToDisable = $(".v-container[data-variant-type=" + otherVariantType +"][data-attribute-value-id=" + result.AttributeValueIds[i]+ "]");
                                itemToDisable.addClass("disabled");
                                itemToDisable.removeClass("activated");
                                itemToDisable.find("a").prop('title',result.InfoMsg);
                            }
                        }
                    }
                });
            };


        self.getChildPlidAction = function(motherPlid, attributeValueId1, attributeValue2Id, lockedVariantType){
            var param = {
                MotherPlid : motherPlid,
                AttributeValueId1: attributeValueId1,
                AttributeValueId2: attributeValue2Id,
                LockedVariantType: lockedVariantType
            };

            return self.service.PostAction("GetVariantChildPlidAsync", param);
        };

        //gives us a liste of attributevaluesids from the variant that are not available for the given attribute type
        self.getInactiveAttributeValuesAction = function(motherPlid, selectedAttributeValueId, attributeType){
            var param = {
                MotherPlid : motherPlid,
                AttributeValueId: selectedAttributeValueId,
                VariantType: attributeType
            };

            return self.service.PostAction("GetInactiveAttributeValues",param);
        };
           
        //show the variant-selector since it is now ready
        if(parent.id === '#PanelProductInfo'){
            jQuery(parent.id).addClass("variant-ready");
        }
   };
})(jQuery);